import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppNgZorroAntdModule } from './components/ng-zorro/ng-zorro-antd.module';
import { HasAnyAuthorityDirective } from "./directives/has-any-authority.directive";
import { SideNavDirective } from './directives/side-nav.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { IconFilterPipe } from './pipes/iconFilter.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { CpfPipe } from "./pipes/cpf.pipe";
import { NgxMaskModule } from "ngx-mask";
import { DocumentoListComponent } from "./components/documento-list/documento-list.component";
import { DocumentoSelectListModalComponent } from "./components/documento-select-list-modal/documento-select-list-modal.component";
import { RelatorListComponent } from "./components/relator-list/relator-list.component";
import { RelatorSelectListModalComponent } from "./components/relator-select-list-modal/relator-select-list-modal.component";

@NgModule({
    declarations: [
        SearchPipe,
        DateFormatPipe,
        IconFilterPipe,
        SideNavDirective,
        HasAnyAuthorityDirective,
        CpfPipe,
        DocumentoListComponent,
        DocumentoSelectListModalComponent,
        RelatorListComponent,
        RelatorSelectListModalComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        AppNgZorroAntdModule,
        SearchPipe,
        SideNavDirective,
        DateFormatPipe,
        IconFilterPipe,
        HasAnyAuthorityDirective,
        CpfPipe,
        NgxMaskModule,
        DocumentoListComponent,
        DocumentoSelectListModalComponent,
        RelatorListComponent,
        RelatorSelectListModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        AppNgZorroAntdModule,
        NgxMaskModule.forRoot(),
    ],
    providers: []
})

export class SharedModule { }
