<div class="text-right" *ngIf="showAdd">
    <button nz-button nzType="primary" (click)="abrirModalAdicionar()">
        <span>Adicionar</span>
    </button>
</div>

<div>
    <ng-container>
        <button  *ngIf="showDelete" nz-button nzType="secondary" title="Excluir" nzShape="circle" (click)="excluirEmLote()" [disabled]="checkListComponent.setOfCheckedId.size === 0" class="mr-2">
            <i nz-icon nzType="delete" nzTheme="outline"></i>
        </button>
    </ng-container>
</div>


<nz-table #table class="m-t-15"
    nzShowSizeChanger 
    [nzFrontPagination]="true" 
    [nzData]="listTable"
    [nzLoading]="fetching" 
    [nzTotal]="totalElements"
    [nzPageIndex]="pageIndex" 
    [nzPageSize]="pageSize"
    (nzPageIndexChange)="onPageIndexChanged($event)" 
    (nzPageSizeChange)="onPageSizeChanged($event)"
    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">

    <thead>
        <tr>
            <th *ngIf="showSelectTable"
                [(nzChecked)]="checkListComponent.checkedAll"
                [nzIndeterminate]="checkListComponent.indeterminate"
                (nzCheckedChange)="checkListComponent.onAllChecked($event)"
                style="width:5%"  class="text-center"
            ></th>
            <th>Tipo</th>
            <th>Nome</th>
            <th *ngIf="showActions" class="text-center" style="width:5%">Ação</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of table.data">
            <td *ngIf="showSelectTable" [nzChecked]="checkListComponent.setOfCheckedId.has(item)" (nzCheckedChange)="checkListComponent.onItemChecked(item, $event)"></td>
            <td>{{item?.tipo}}</td>
            <td>{{item?.nome}}</td>
            
            <td *ngIf="showActions" class="text-center">
                <div>
                    <a class="text-dark font-size-20" nz-dropdown [nzTrigger]="'click'"
                        [nzPlacement]="'bottomRight'" [nzDropdownMenu]="assignedTasksDropDown">
                        <i nz-icon nzType="down-circle" nzTheme="twotone"></i>
                    </a>
                    <nz-dropdown-menu #assignedTasksDropDown="nzDropdownMenu">
                        <ul nz-menu>
                            <li *ngIf="showDelete"  nz-menu-item (click)="excluir(item.id)">
                                <i nz-icon nzType="delete" nzTheme="outline"></i>
                                <span class="m-l-5">Excluir</span>
                            </li>
                        </ul>
                    </nz-dropdown-menu>
                </div>
            </td>
        </tr>
    </tbody>
</nz-table>

<app-relator-select-list-modal [numeroProcesso]="numeroProcesso" title="Adicionar Relator" [showModal]="showModalAdicionar" [list]="listModal" (cancelEvent)="fecharModalAdicionar()" (addEvent)="adicionarModal($event)" (searchEvent)="consultarListaModal($event)"></app-relator-select-list-modal>
