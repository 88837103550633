import { BehaviorSubject } from "rxjs";

export class FilterList<E> {

    filter = {} as E;
  
    public searchChangeSubject = new BehaviorSubject<any>({});
    

    search(){
        this.searchChangeSubject.next(this.filter);
    }

    clear(){
        this.filter = {} as E;
    }
}
