import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckList } from 'src/app/shared/interfaces/check-list.type';
import { Documento } from 'src/app/shared/models/documento.model';


@Component({
  selector: 'app-documento-select-list-modal',
  templateUrl: './documento-select-list-modal.component.html'
})
export class DocumentoSelectListModalComponent implements OnInit {
  
  @Input() title:string = "Documentos";
 
  @Input() list = [];
  @Input() showModal = false;
  @Input() numeroProcesso: string;

  @Output() cancelEvent = new EventEmitter();
  @Output() addEvent = new EventEmitter();
  @Output() searchEvent = new EventEmitter();

  form: FormGroup;

  checkListComponent = new CheckList<Documento>();

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {

  }

  cancelModal(): void {
    this.clearModal();
    this.cancelEvent.emit();
  }

  adicionar(): void {
    this.addEvent.emit({selecionados:this.checkListComponent.setOfCheckedId});
  }


  clearModal(): void {
    this.clearList();
  }

  clearList(): void {
    this.checkListComponent.setOfCheckedId.clear();
    this.list = [];
    this.checkListComponent.listOfCurrentPageData = [];
  }
}
