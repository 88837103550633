import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from 'src/app/shared/interfaces/page.type';
import { Documento } from 'src/app/shared/models/documento.model';
import { Parte } from 'src/app/shared/models/parte.model';
import { Relator } from 'src/app/shared/models/relator.model';
import { Reuniao } from 'src/app/shared/models/reuniao.model';
import { Setor } from 'src/app/shared/models/setor.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: "root"
})
export class ProcessoService{

  protected readonly apiUrl = `${environment.baseUrl}/processo`;

  constructor(protected http: HttpClient) {
  }

  possuiAcesso(numeroProcesso): Observable<boolean> {
      return this.http.get<boolean>(`${this.apiUrl}/${numeroProcesso}/possui-acesso`);
  }

  consultarDocumentos(numeroProcesso, search?: { [key: string]: string }): Observable<Page<Documento[]>> {
    const params = new HttpParams({ fromObject: search });
    return this.http.get<Page<Documento[]>>(`${this.apiUrl}/${numeroProcesso}/documento/consulta`, { params });
  }

  consultarPartes(numeroProcesso, search?: { [key: string]: string }): Observable<Page<Parte[]>> {
    const params = new HttpParams({ fromObject: search });
    return this.http.get<Page<Parte[]>>(`${this.apiUrl}/${numeroProcesso}/parte/consulta`, { params });
  }

  consultarRelatores(numeroProcesso, search?: { [key: string]: string }): Observable<Page<Relator[]>> {
    const params = new HttpParams({ fromObject: search });
    return this.http.get<Page<Relator[]>>(`${this.apiUrl}/${numeroProcesso}/relator/consulta`, { params });
  }

  consultarOrgaoJulgador(numeroProcesso, search?: { [key: string]: string }): Observable<Setor[]> {
    const params = new HttpParams({ fromObject: search });
    return this.http.get<Setor[]>(`${this.apiUrl}/${numeroProcesso}/orgao-julgador/consulta`, { params });
  }

  consultarReuniao(numeroProcesso, search?: { [key: string]: string }): Observable<Page<Reuniao[]>> {
    const params = new HttpParams({ fromObject: search });
    return this.http.get<Page<Reuniao[]>>(`${this.apiUrl}/${numeroProcesso}/reuniao/consulta`, { params });
  }
}
