import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ListComponent } from 'src/app/shared/components/abstract/list.component';
import { IPagination, Pagination } from 'src/app/shared/interfaces/pagination.type';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ProcessoService } from 'src/app/core/services/processo.service';
import { Documento } from 'src/app/shared/models/documento.model';


@Component({
  selector: 'app-documento-list',
  templateUrl: './documento-list.component.html',
  styleUrls: ['./documento-list.component.css']
})
export class DocumentoListComponent extends ListComponent<Documento> implements OnInit {

  @Input() showActions = true;
  @Input() showDelete = true;
  @Input() showEdit = true;
  @Input() showAdd = true;
  @Input() showDetail = true;
  @Input() showSelectTable = true;
  @Input() numeroProcesso: string;

  showModalAdicionar = false;
  listModal: Documento[];
  listTable: Documento[];

  confirmModal?: NzModalRef;
  resource: Documento;
  
  protected pageChangeSubject = new BehaviorSubject<IPagination>(new Pagination());

  
  constructor(public processoService: ProcessoService,
      private modal: NzModalService) { 
    super();


  }

  ngOnInit(): void {
    this.pageChangeEvent.subscribe((data) => this.pageChangeSubject.next(data));
    this.loadData();
  }

  loadData(){
    this.fetching = false;
    this.listTable = [...this.list];
  }

  excluir(id){
    this.showConfirm(
      "Confirmação",
      "Deseja excluir este documento?",
      () => {
            let index = this.list.findIndex(item => item.id === id);
            if (index !== -1) {
              this.list.splice(index, 1);
              this.listTable = [...this.list];
            }
          }
    )
    
  }

  excluirEmLote(){
    this.showConfirm(
      "Confirmação",
      "Deseja excluir este(s) documento(s)?",
      () => {
            this.checkListComponent.setOfCheckedId.forEach(data => {
              let index = this.list.findIndex(item => item.id === data.id);
              if (index !== -1) {
                this.list.splice(index, 1);
                this.listTable = [...this.list];
              }
            })
            this.checkListComponent.setOfCheckedId.clear()
          }
    )
  }

  showConfirm(title:string, mensagem:string, func): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: mensagem,
      nzOnOk: () => func()
    });
  }

  onCurrentPageDataChange($event){
    this.checkListComponent.onCurrentPageDataChange($event)
  }


   //Events Modal 
  abrirModalAdicionar(): void {
    this.showModalAdicionar = true;
    this.consultarListaModal(this.numeroProcesso)
  }

  fecharModalAdicionar(): void {
    this.showModalAdicionar = false;
    this.listModal = [];
  }

  consultarListaModal(numeroProcesso){
    let param = {
                  size:'1000'
                }

    this.processoService.consultarDocumentos(numeroProcesso, param).subscribe((documentos) =>{
      this.listModal = documentos.content.filter(data => !this.list.some(item => item.id === data.id))
    });
  }

  adicionarModal($event: {selecionados: Set<Documento>}): void {
    this.list.push(...Array.from($event.selecionados.values()))
   // this.listTable = Object.assign([], this.list);
    this.listTable = [...this.list];
    //this.list = [...this.list, ...Array.from($event.selecionados.values())];
    $event.selecionados.clear();
    this.listModal = this.listModal.filter(data => !this.list.some(item => item.id === data.id))
  }
  
}
