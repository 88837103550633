import { EventEmitter, Input, Output } from "@angular/core";
import { PAGINACAO_PAGE_SIZE } from "../../constantes";
import { CheckList } from "../../interfaces/check-list.type";
import { FilterList } from "../../interfaces/filter-list.type";
import { IPagination } from "../../interfaces/pagination.type";



export abstract class ListComponent<E> {

    @Input() list:E[] = [];
    @Input() fetching: boolean = true;
    @Input() totalElements: number = 0;
    @Output() totalElementsChange = new EventEmitter<number>();

    @Output() pageChangeEvent = new EventEmitter<IPagination>();

    pageIndex: number = 1;
    pageSize: number = PAGINACAO_PAGE_SIZE;

    public checkListComponent = new CheckList<E>();
    public filterListComponent = new FilterList<E>();

    constructor() {
        this.filterListComponent.searchChangeSubject.subscribe(() => this.onPageIndexChanged(1));
    }

    onPageIndexChanged(index: number) {
        this.pageIndex = index;
        this.pageChangeEvent.emit({pageSize: this.pageSize, pageIndex: this.pageIndex});
    }
    
    onPageSizeChanged(size: number) {
        this.pageSize = size;
        this.pageChangeEvent.emit({pageSize: this.pageSize, pageIndex: this.pageIndex});
    }
    
}
