import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [

    {
        path: '', 
        redirectTo: "pesquisa",
        pathMatch: "full"
    },
    {
        path: 'pesquisa',
        data: {
            title: 'Pesquisa'
        },
        loadChildren: () => import('../../modules/admin/pesquisa/pesquisa.module').then(m => m.PesquisaModule),
    },
    {
        path: 'ementas',
        data: {
            title: 'Ementas'
        },
        loadChildren: () => import('../../modules/admin/ementa/ementa.module').then(m => m.EmentaModule),
    },
    {
        path: 'boletim-informativo',
        data: {
            title: 'Boletins Informativos'
        },
        loadChildren: () => import('../../modules/admin/boletim-informativo/boletim-informativo.module').then(m => m.BoletimInformativoModule),
    },
    {
        path: '',
        loadChildren: () => import('../../modules/admin/decisao/decisao.module').then(m => m.DecisaoModule),
    }

];