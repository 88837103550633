export class CheckList<E> {

    checkedAll = false;
    indeterminate = false;
    listOfCurrentPageData: E[] = [];
    public setOfCheckedId = new Set<E>();

    updateCheckedSet(item: E, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(item);
        } else {
            this.setOfCheckedId.delete(item);
        }
    }

    onItemChecked(item: E, checked: boolean): void {
        this.updateCheckedSet(item, checked);
        this.refreshCheckedStatus();
    }

    onAllChecked(value: boolean): void {
        this.listOfCurrentPageData.filter((data) => !(<any>data).disabled).forEach(item => this.updateCheckedSet(item, value));
        this.refreshCheckedStatus();
    }

    onCurrentPageDataChange($event: E[]): void {
        this.listOfCurrentPageData = $event;
        this.refreshCheckedStatus();
    }

    refreshCheckedStatus(): void {
        const listOfEnabledData = this.listOfCurrentPageData.filter((data) => !(<any>data).disabled);
        this.checkedAll = listOfEnabledData.length>0? listOfEnabledData.every(item => this.setOfCheckedId.has(item)):false;
        this.indeterminate = listOfEnabledData.some(item => this.setOfCheckedId.has(item)) && !this.checkedAll;
    }

    isChecked(): boolean{
        return this.setOfCheckedId.size>0
    }
}
