import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ListComponent } from 'src/app/shared/components/abstract/list.component';
import { IPagination, Pagination } from 'src/app/shared/interfaces/pagination.type';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ProcessoService } from 'src/app/core/services/processo.service';

import { Relator } from 'src/app/shared/models/relator.model';
import { Setor } from 'src/app/shared/models/setor.model';


@Component({
  selector: 'app-relator-list',
  templateUrl: './relator-list.component.html',
  styleUrls: ['./relator-list.component.css']
})
export class RelatorListComponent extends ListComponent<Relator> implements OnInit {

  @Input() showActions = true;
  @Input() showDelete = true;
  @Input() showEdit = true;
  @Input() showAdd = true;
  @Input() showDetail = true;
  @Input() showSelectTable = true;
  @Input() numeroProcesso: string;
  @Input() orgaoJulgador: Setor;

  showModalAdicionar = false;
  listModal: Relator[];
  listTable: Relator[];

  confirmModal?: NzModalRef;
  
  protected pageChangeSubject = new BehaviorSubject<IPagination>(new Pagination());

  
  constructor(public processoService: ProcessoService,
      private modal: NzModalService) { 
    super();
  }

  ngOnInit(): void {
    this.pageChangeEvent.subscribe((data) => this.pageChangeSubject.next(data));
    this.loadData();
  }

  loadData(){
    this.fetching = false;
    this.listTable = [...this.list];
  }

  excluir(id){
    this.showConfirm(
      "Confirmação",
      "Deseja excluir este relator?",
      () => {
            let index = this.list.findIndex(item => item.id === id);
            if (index !== -1) {
              this.list.splice(index, 1);
              this.listTable = [...this.list];
            }
          }
    )
    
  }

  excluirEmLote(){
    this.showConfirm(
      "Confirmação",
      "Deseja excluir este(s) relator(es)?",
      () => {
            this.checkListComponent.setOfCheckedId.forEach(selecionado => {
              let index = this.list.findIndex(item => item.id === selecionado.id);
              if (index !== -1) {
                this.list.splice(index, 1);
                this.listTable = [...this.list];
              }
            })
            this.checkListComponent.setOfCheckedId.clear()
          }
    )
  }

  showConfirm(title:string, mensagem:string, func): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: title,
      nzContent: mensagem,
      nzOnOk: () => func()
    });
  }

  onCurrentPageDataChange($event){
    this.checkListComponent.onCurrentPageDataChange($event)
  }


   //Events Modal
  abrirModalAdicionar(): void {
    this.showModalAdicionar = true;
    this.consultarListaModal(this.numeroProcesso)
  }

  fecharModalAdicionar(): void {
    this.showModalAdicionar = false;
    this.listModal = [];
  }

  consultarListaModal(numeroProcesso){
    let param = {
                  size:'1000',
                  idOrganizacao: this.orgaoJulgador.idOrganizacao.toString(),
                  codiSetor: this.orgaoJulgador.codiSetor.toString()
                }

    this.processoService.consultarRelatores(numeroProcesso, param).subscribe((relatores) =>{
      this.listModal = relatores.content.filter(data => !this.list.some(item => item.id === data.id))
    });
  }

  adicionarModal($event: {selecionados: Set<Relator>}): void {
   this.list.push(...Array.from($event.selecionados.values()))
   this.listTable = [...this.list];

    $event.selecionados.clear();
    this.listModal = this.listModal.filter(data => !this.list.some(item => item.id === data.id))
  }
}
