<nz-modal [(nzVisible)]="showModal" [nzTitle]="title" 
    [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="cancelModal()" nzWidth="800px">
    <ng-template #modalContent>
        <div>            
            <nz-table #table 
                [nzData]="list"  
                (nzCurrentPageDataChange)="checkListComponent.onCurrentPageDataChange($event)"
                [nzFrontPagination]="false" style="max-height: calc(100vh - 380px);overflow-y: auto;">
                <thead>
                  <tr>
                    <th style="width: 60px;"
                        [(nzChecked)]="checkListComponent.checkedAll"
                        [nzIndeterminate]="checkListComponent.indeterminate"
                        (nzCheckedChange)="checkListComponent.onAllChecked($event)"
                    ></th>
                    <th>Tipo</th>
                    <th>Cargo</th>
                    <th>Nome</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of table.data">
                    <td [nzChecked]="checkListComponent.setOfCheckedId.has(item)" (nzCheckedChange)="checkListComponent.onItemChecked(item, $event)"></td>
                    <td>{{ item.tipo }}</td>
                    <td>{{ item.cargo }}</td>
                    <td>{{ item.nome }}</td>
                  </tr>
                </tbody>
              </nz-table>
        </div>
    </ng-template>
    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="cancelModal()">Sair</button>
        <button nz-button nzType="primary" (click)="adicionar()" [nzLoading]="isConfirmLoading" [disabled]="this.checkListComponent.setOfCheckedId.size === 0">Adicionar</button>
    </ng-template>
  </nz-modal>