import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/pesquisa',
        title: 'Pesquisa',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/ementas',
        title: 'Ementas',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/resolucoes',
        title: 'Resoluções',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/provimentos',
        title: 'Provimentos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/instrucoes-normativas',
        title: 'Instruções Normativas',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/portarias',
        title: 'Portarias',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/sumulas',
        title: 'Súmulas',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    },
    {
        path: '/boletim-informativo',
        title: 'Boletins Informativos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: []
    }
   
]    